<template>
    
      <!-- Main content -->
      <section class="content">
        <div class="btn-box">
            <el-form :inline="true">
                <el-form-item label="user_id：">
                    <el-input v-model="uid" placeholder="请输入user_id"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="seach(1)">搜索</el-button></el-button> 
                </el-form-item>
            </el-form>
        </div>
        <el-table
            v-loading="loading"
            :data="JPushList"
            border>
            <el-table-column
                fixed
                prop="id"
                label="用户user_id(user_key)"
                width="200"
            >
                <template slot-scope="scope">
                    {{scope.row.id}}({{scope.row.user_key}})
                </template>
</el-table-column>
</el-table-column>
<el-table-column prop="jpush_id" label="极光RID" width="280"></el-table-column>
<el-table-column prop="os_name" label="系统" width="100"></el-table-column>
<el-table-column label="创建时间">
    <template slot-scope="scope">
        {{scope.row.create_time | dateTime}}
    </template>
</el-table-column>
<el-table-column label="更新时间">
    <template slot-scope="scope">
        {{scope.row.update_time | dateTime}}
    </template>
</el-table-column>
</el-table>
<el-pagination background layout="total, sizes,prev, pager, next,jumper" :total="total" @size-change="sizeChange" @current-change="currentChange">
</el-pagination>

</section>
<!-- /.content -->
</template>

<script>
    import * as api from '@/config/api'
    export default {
        name: 'JPush',
        components: {},

        data() {
            return {
                JPushList: [],
                total: 0,
                page: 1,
                pagesize: 10,
                uid: '',
                orderId: '',
                loading: true
            }
        },
        watch: {},
        filters: {
            status(val) {
                let common = JSON.parse(window.localStorage.getItem('common'))
                let name = ''
                if (JSON.stringify(common.order_status_list).indexOf(val) != -1) {
                    common.order_status_list.map((a, index) => {
                        if (val === a.type) {
                            name = a.name
                        }
                    })
                }
                return name
            },
            dateTime(val) {
                let day = new Date(Number(val) * 1000)
                if (Number(val) === 0) {
                    return ''
                }
                return day.getFullYear() + '-' + `${(day.getMonth() + 1) < 10 ? '0' + (day.getMonth() + 1) : (day.getMonth() + 1)}` + '-' + `${day.getDate()<10?'0'+day.getDate():day.getDate()}` + ' ' + `${day.getHours()<10?'0'+day.getHours():day.getHours()}` + ':' + `${day.getMinutes()<10?'0'+day.getMinutes():day.getMinutes()}` + ':' + `${day.getSeconds()<10?'0'+day.getSeconds():day.getSeconds()}`
            }
        },
        mounted() {
            this.getJpushList()
        },
        methods: {
            close() {
                this.show = false
                this.getJpushList()
            },
            getJpushList(page, size) {
                this.loading = true
                api.getJpushList({
                    user_id: this.uid,
                    page: page ? page : this.page,
                    pagesize: size ? size : this.pagesize
                }, res => {
                    this.JPushList = res.data.list
                    this.total = Number(res.data.count)
                    this.loading = false
                })
            },
            sizeChange(val) {
                this.pagesize = val
                this.getJpushList()
            },
            currentChange(val) {
                this.page = val
                this.getJpushList()
            },
            seach(page, size) {
                this.getJpushList(page, size)
            }
        }
    }
</script>
<style scoped>
    .cell {
        text-align: center;
    }
    
    .btn-box {
        text-align: left;
        margin-bottom: 20px;
    }
    
    img {
        width: 150px;
    }
</style>